import React from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import debounce from "lodash/debounce";
import fetch from "../lib/fetch";
import colors from "../lib/colors";
import {Input} from "./Input";
import Col from "./ui/Col";
import Row from "./ui/Row";
import Push from "./ui/Push";
import {FieldWithLabel} from "../lib/forms/FieldWithLabel";

const Emph = styled("span")({
  color: colors.fade(colors.dark, 0.6),
  fontWeight: "bold",
});

const cache = {};
let currentHandler = null;

const check = debounce((value, cb) => {
  currentHandler = cb;
  const cbIfStillCurrent = data => {
    if (cb === currentHandler) cb(data);
  };
  if (cache[value]) {
    cbIfStillCurrent(cache[value]);
  } else {
    fetch("/services/password-quality", {
      method: "POST",
      body: JSON.stringify({password: value}),
    }).then(
      res => {
        cache[value] = res;
        cbIfStillCurrent(cache[value]);
      },
      e => console.warn(e)
    );
  }
}, 200);

class InnerPasswordWithScore extends React.Component {
  state = {
    focused: false,
    quality: null,
    showError: false,
  };

  handleChange = value => {
    if (this.props.onChange) this.props.onChange(value);
    if (value) {
      check(value, data => this.setState({quality: data}));
      this.setState({showError: false});
    } else {
      currentHandler = null;
      this.setState({quality: null, showError: false});
    }
  };

  handleFocus = e => {
    if (this.props.onFocus) this.props.onFocus(e);
    this.setState({focused: true, showError: true});
  };

  handleBlur = e => {
    if (this.props.onBlur) this.props.onBlur(e);
    this.setState({focused: false, showError: false});
  };

  handleContainerClick = () => {
    this.focus();
  };

  render() {
    const {
      wrappedRef,
      errors,
      onChange: _,
      onFocus: _2,
      onBlur: _3,
      onClick: _5,
      ...rest
    } = this.props;

    const {quality} = this.state;

    return (
      <Col fillParent style={{minWidth: 0}}>
        <Input
          type="password"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          ref={wrappedRef}
          {...rest}
        />
        <Row
          css={css({marginTop: "-0.5em", fontSize: "0.7rem", color: colors.fade(colors.dark, 0.3)})}
          px={3}
        >
          {quality ? (
            <Row sp={2} fillParent>
              <div>
                Score: <Emph>{quality.score} / 4</Emph>
              </div>
              <Push />
              <div>
                Time to crack: <Emph>{quality.crackTimeDisplay}</Emph>
              </div>
            </Row>
          ) : (
            "Start typing to see score"
          )}
        </Row>
      </Col>
    );
  }
}

const PasswordWithScore = React.forwardRef((props, ref) => (
  <InnerPasswordWithScore wrappedRef={ref} {...props} />
));

export const PasswordWithScoreWithLabel = React.forwardRef((props, ref) => (
  <FieldWithLabel as={PasswordWithScore} ref={ref} {...props} />
));

export default PasswordWithScore;
