import React from "react";
import Img from "gatsby-image";
import {Link, navigate} from "gatsby";
import {useStaticQuery, graphql} from "gatsby";
import Col from "../ui/Col";
import mq from "../ui/media-queries";
import Header, {HeaderLogo, HeaderThemeSentinel} from "../Header";
import {InputWithLabel} from "../Input";
import {PlainButton} from "../Button";
import {InnerCardFall} from "../CardFall";
import {Box, XCol, XPush, XRow, XStrip} from "../xui/Box";
import {themeDark, themePurple} from "../../page-styles/landingpage.css";
import {uiStyles} from "../xui/xui.css";
import Row from "../ui/Row";
import Icon from "../Icon";
import {SignupProviderButton} from "../../pages/create-account";
import cx from "../xui/cx";

export const HeroForm = ({placeholder, buttonLabel, authProviderLabel}) => {
  const [email, setEmail] = React.useState("");
  const handleSubmit = e => {
    e.preventDefault();
    navigate("/create-account/", {state: {email}});
  };
  return (
    <Col as="form" onSubmit={handleSubmit} sp={2}>
      <InputWithLabel
        placeholder={placeholder}
        value={email}
        onChange={val => setEmail(val)}
        size="md"
        align="center"
        postfix={
          <PlainButton
            type="submit"
            size="default"
            className={uiStyles.size.sm}
            css={{
              paddingTop: "0.8rem",
              paddingBottom: "0.7rem",
              flex: "none",
              [mq.sm]: {display: "none"},
            }}
          >
            {buttonLabel}
          </PlainButton>
        }
      />
      <PlainButton
        type="submit"
        size="default"
        className={uiStyles.size.sm}
        css={{
          paddingTop: "0.8rem",
          paddingBottom: "0.7rem",
          display: "none",
          [mq.sm]: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        {buttonLabel}
      </PlainButton>
      <Row sp={2} align="center" justify="center" breakAt={mq.sm}>
        <Box bold size="sm" color="secondary">
          {authProviderLabel}
        </Box>
        <Row sp={2} align="center">
          <SignupProviderButton provider="discord" onDone={() => navigate("/create-account/")}>
            <Row sp={1} align="center">
              <Icon.Discord />
              <div>Discord</div>
            </Row>
          </SignupProviderButton>
          <SignupProviderButton provider="google" onDone={() => navigate("/create-account/")}>
            <Row sp={1} align="center">
              <Icon.Google />
              <div>Google</div>
            </Row>
          </SignupProviderButton>
        </Row>
      </Row>
    </Col>
  );
};

export const HeroCharacterImage = ({img, className}) => {
  const svgMatch = img.match(/(.+).svg$/);
  if (!svgMatch) return null;
  return (
    <img src={require(`../../imgs/hero-images/${svgMatch[1]}.svg`)} alt="" className={className} />
  );
};

const featureImageQuery = graphql`
  {
    allFile(
      filter: {
        relativeDirectory: {glob: "{feature-images/*,feature-images,hero-images}"}
        extension: {ne: "svg"}
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 960, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

const HeroImg = ({img, imgSize}) => {
  const {allFile} = useStaticQuery(featureImageQuery);
  const imgData = allFile.nodes.find(n => n.relativePath.endsWith(img));
  if (!imgData) return null;

  return (
    <Box
      as={Img}
      fluid={imgData.childImageSharp.fluid}
      alt=""
      rounded="md"
      borderWidth={1}
      borderColor="imgBorder"
      heroHighlight
      width="100%"
      maxWidth={imgSize}
    />
  );
};

const Logo = ({noLink}) => (
  <XRow absolute left={0} right={0} px={4} py={2} style={{zIndex: 6}}>
    <HeaderLogo noLink={noLink} />
  </XRow>
);

const minHeightBySize = {
  sm: null,
  md: "md",
  lg: "fullScreen",
};

const Hero = ({
  title,
  subline,
  size = "lg",
  img,
  imgSize = size,
  bottomImg,
  children,
  noNav,
  noCardFall,
  noHeader,
  bg = "gradientToDark",
  sentinelTheme = themePurple,
  noPaddingBottom,
}) => (
  <>
    {noHeader ? null : noNav ? <Logo noLink={noNav !== "withLogoLink"} /> : <Header />}
    <XCol minHeight={minHeightBySize[size]} className={themeDark} relative bg={bg}>
      <HeaderThemeSentinel theme={sentinelTheme} />
      {!noCardFall && <InnerCardFall cardPer100px={size === "lg" ? 3 : 1} />}
      <XCol
        fillParent
        relative
        zIndex={1}
        pb={noPaddingBottom ? 0 : bottomImg ? 5 : size !== "lg" ? 9 : 8}
      >
        <XPush />
        <XStrip width="xxl" sp={9}>
          <XCol
            align="center"
            justify="center"
            sp={subline ? 6 : 7}
            minHeight={size === "lg" ? "70vh" : null}
            pt={size === "lg" ? 10 : 9}
            md_pt={size === "lg" ? 9 : 8}
          >
            <Box
              as="h1"
              weight="normal"
              size="xxxl"
              maxWidth="lg"
              color="purple100"
              textAlign="center"
            >
              {title}
            </Box>
            {subline && (
              <Box as="h2" maxWidth="lg" size="xl" color="purple300" textAlign="center">
                {subline}
              </Box>
            )}
            {children}
          </XCol>
          {img && (
            <XCol align="center">
              <HeroImg img={img} imgSize={imgSize} />
            </XCol>
          )}
        </XStrip>
        <XPush />
      </XCol>
      {bottomImg && (
        <XCol align="center" relative>
          <HeroCharacterImage img={bottomImg} />
        </XCol>
      )}
    </XCol>
  </>
);

export default Hero;

export const ProductHero = ({children, logo}) => (
  <>
    <XCol minHeight="fullScreen" className={themeDark} relative bg="gradientToDark">
      <XRow absolute left={0} right={0} px={5} py={2} style={{zIndex: 6}}>
        {typeof logo === "string" ? (
          <Box bold size="xxl">
            {logo}
          </Box>
        ) : (
          logo
        )}
        <XPush />
        <Link to="/">
          <Box
            as="img"
            height="2"
            width="auto"
            forwardProps={{width: 136, height: 40}}
            src={require("../../imgs/powered-by-cdx.svg")}
            style={{opacity: 0.6}}
          />
        </Link>
      </XRow>
      <HeaderThemeSentinel theme={themePurple} />
      <InnerCardFall cardPer100px={3} fadeOut />

      <XCol fillParent relative zIndex={1} pb={8}>
        <XPush />
        <XStrip
          width="xxl"
          sp={9}
          className={cx(uiStyles.maxWidth["100%"], uiStyles.overflow.hidden)}
        >
          <XCol align="center" justify="center" sp={6} minHeight={"70vh"} pt={8} md_pt={7}>
            {children}
          </XCol>
        </XStrip>
        <XPush />
      </XCol>
    </XCol>
  </>
);
