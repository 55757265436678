import {useRequestHandler} from "../../lib/fetch";
import {loadAppInfo} from "../AppInfo";

const providerInfo = {
  discord: {
    OAuthPath: "/integrations/discord/create-login-oauth-url",
    windowTitle: "Discord Authentication",
  },
  google: {
    OAuthPath: "/integrations/google/create-oauth-url",
    windowTitle: "Google Authentication",
  },
};

// intent: "login" | "signup"
export const useAuthFlow = ({onDone, provider, intent}) => {
  const {OAuthPath, windowTitle} = providerInfo[provider];
  const {loading, requestHandler} = useRequestHandler({
    path: OAuthPath,
  });
  return {
    onClick: async () => {
      const {authUrl} = await requestHandler({
        targetUrl: window.location.href,
        origin: window.location.origin,
        intent,
      });
      const windowRes = await openAuthorizeWindow(windowTitle, authUrl).catch(e => {
        if (e === "window_closed") {
          return Promise.resolve(null);
        } else {
          return Promise.reject(e);
        }
      });
      if (windowRes === null) return null;
      if (windowRes.cdxUserId) await loadAppInfo();
      if (onDone) return onDone(windowRes.cdxUserId);
    },
    disabled: loading,
  };
};

const openAuthorizeWindow = (title, url) =>
  new Promise((resolve, reject) => {
    const width = 550;
    const height = 725;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;
    let finished = false;

    // can be null in e.g. Firefox 89
    const authWindow = window.open(
      url,
      title,
      `toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`
    );

    const intervalId = setInterval(() => {
      if (authWindow && authWindow.closed) {
        clearInterval(intervalId);
        if (!finished) reject("window_closed");
      }
    }, 500);

    const receiveMessage = event => {
      if (event.origin !== process.env.GATSBY_API_HOST || event.source !== authWindow) {
        console.warn("Invalid origin");
      } else {
        window.removeEventListener("message", receiveMessage, false);
        finished = true;
        if (event.data?.type === "done") {
          resolve(event.data.payload);
        } else {
          reject("Access denied");
        }
      }
    };
    window.addEventListener("message", receiveMessage, false);
  });
