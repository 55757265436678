import React from "react";
import LinkOrButton from "../LinkOrButton";
import css from "@emotion/css";
import {rawButtonStyles, ButtonBehaviour} from "./button-helpers";
import xcolors from "../../lib/xcolors";

const styles = {
  default: css({
    fontWeight: "bold",
    border: `1px solid`,
    ":focus": {
      outline: "none",
    },
    borderRadius: "2rem",
  }),
  active: css({
    color: xcolors.white,
    backgroundColor: xcolors.active,
    borderColor: xcolors.active,
    ":hover": {
      backgroundColor: xcolors.active_l1,
    },
    ":focus": {
      boxShadow: `0 0 0 0.15rem ${xcolors.active_l4}`,
    },
  }),
  colors: {
    default: css({
      color: xcolors.purple500,
      borderColor: xcolors.purple300,
      ":hover": {
        backgroundColor: xcolors.black_a05,
      },
      ":focus": {
        boxShadow: `0 0 0 0.15rem ${xcolors.purple100}`,
      },
    }),
    white: css({
      color: xcolors.white,
      borderColor: xcolors.white,
      ":hover": {
        backgroundColor: xcolors.white_a10,
      },
      ":focus": {
        boxShadow: `0 0 0 0.15rem ${xcolors.white_a25}`,
      },
    }),
    blue: css({
      color: xcolors.link,
      borderColor: xcolors.link,
      ":hover": {
        backgroundColor: xcolors.white_a10,
      },
      ":focus": {
        boxShadow: `0 0 0 0.15rem ${xcolors.white_a25}`,
      },
    }),
  },
  sizes: {
    xs: css({
      boxShadow: `0 0 0 0.15rem transparent`,
      padding: "0.2rem 1rem 0.15rem",
      fontSize: `${10 / 16}rem`,
    }),
    sm: css({
      boxShadow: `0 0 0 0.15rem transparent`,
      padding: "0.5rem 1rem 0.4rem",
      fontSize: `${10 / 16}rem`,
    }),
    md: css({
      boxShadow: `0 0 0 0.2rem transparent`,
      padding: "0.7rem 1.2rem 0.6rem",
      fontSize: `${14 / 16}rem`,
    }),
  },
  squarePadding: {
    sm: css({padding: "0.15rem"}),
    md: css({padding: "0.25rem"}),
  },
  disabled: css({
    cursor: "initial",
    opacity: 0.5,
  }),
};

const PlainGhostButton = React.forwardRef(({color, size, square, active, ...rest}, ref) => (
  <LinkOrButton
    ref={ref}
    css={[
      rawButtonStyles,
      styles.default,
      rest.disabled && styles.disabled,
      active ? styles.active : color ? styles.colors[color] : styles.colors.default,
      size ? styles.sizes[size] : styles.sizes.md,
      square && styles.squarePadding[size || "md"],
    ]}
    {...rest}
  />
));

const GhostButton = React.forwardRef((props, ref) => (
  <ButtonBehaviour comp={PlainGhostButton} ref={ref} {...props} />
));

export default GhostButton;
