import React from "react";

const FormCtx = React.createContext(null);

const useControlledState = (value, onChange) => {
  const state = React.useState(value);
  return onChange
    ? [
        value,
        updateFn => {
          const nextVal = updateFn(value);
          if (nextVal !== value) onChange(nextVal);
        },
      ]
    : state;
};

function asyncValidation({
  promisesAndFields,
  currentValidationRunnerRef,
  setFieldMeta,
  setIsValid,
  syncRulesAreValid,
}) {
  const myObj = {};
  currentValidationRunnerRef.current = myObj;
  return Promise.all(
    promisesAndFields.map(({isValidPromise, fieldName, errorMessage}) => {
      setFieldMeta(fieldName, prev => ({...prev, pendingValidation: true}));
      return isValidPromise.then(isValid => {
        if (currentValidationRunnerRef.current !== myObj) return false;
        setFieldMeta(fieldName, prev => ({
          ...prev,
          errors: isValid ? prev.errors : [...prev.errors, errorMessage],
          pendingValidation: false,
        }));
        return isValid;
      });
    })
  ).then(results => {
    const asyncRulesAreValid = results.every(isValid => isValid);
    const isAllValid = syncRulesAreValid && asyncRulesAreValid;
    setIsValid(isAllValid);
    return isAllValid;
  });
}

const validateValuesFn = ({
  values,
  rules: rawRules,
  setFieldMeta,
  currentValidationRunnerRef,
  setIsValid,
  validatedValuesRef,
}) => {
  const promisesAndFields = [];
  let syncRulesAreValid = true;
  const rules = typeof rawRules === "function" ? rawRules(values) : rawRules;
  Object.entries(values).forEach(([fieldName, value]) => {
    const errors = [];
    const fieldValidations = rules[fieldName];
    if (fieldValidations) {
      fieldValidations.forEach(([validator, errorMessage]) => {
        const isValid = validator(value);
        if (isValid === false) {
          syncRulesAreValid = false;
          errors.push(errorMessage);
        } else if (isValid === true) {
          // all fine
        } else if (isValid && isValid.then && typeof isValid.then === "function") {
          promisesAndFields.push({fieldName, isValidPromise: isValid, errorMessage});
        } else {
          console.error(`invalid 'isValid' value for ${fieldName}: ${JSON.stringify(isValid)}`);
        }
      });
    }
    setFieldMeta(fieldName, m =>
      m.errors.length === 0 && errors.length === 0 ? m : {...m, errors}
    );
  });
  validatedValuesRef.current = values;
  if (promisesAndFields.length) {
    return asyncValidation({
      promisesAndFields,
      currentValidationRunnerRef,
      setFieldMeta,
      setIsValid,
      syncRulesAreValid,
    });
  } else {
    return syncRulesAreValid;
  }
};

const initialFieldMeta = {
  errors: [],
  changed: false,
  touched: false,
  pendingValidation: false,
  focussed: false,
};

export const useNeoBagCreator = ({
  values: outerValues,
  onChange: outerOnChange,
  initialValues,
  onSubmit,
  rules,
  initialValidValue,
  validateOn = "change",
}) => {
  if (process.env.NODE_ENV === "development" && !outerValues && !initialValues) {
    throw new Error("Please pass either `values` or `initialValues` to form");
  }
  const [values, setValues] = useControlledState(outerValues || initialValues, outerOnChange);
  const [submitCount, setSubmitCount] = React.useState(0);
  const [fieldMetas, setFieldMetas] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  const bagRef = React.useRef(null);
  const setFieldMeta = React.useCallback((fieldName, fieldInfoOrCb) => {
    if (typeof fieldInfoOrCb === "function") {
      setFieldMetas(allMetas => {
        const prevFieldInfo = allMetas[fieldName];
        const nextFieldInfo = fieldInfoOrCb(prevFieldInfo || initialFieldMeta);
        return nextFieldInfo === prevFieldInfo
          ? allMetas
          : {...allMetas, [fieldName]: nextFieldInfo};
      });
    } else {
      setFieldMetas(allMetas => ({...allMetas, [fieldName]: fieldInfoOrCb}));
    }
  }, []);
  const validatedValuesRef = React.useRef(true);
  const currentValidationRunnerRef = React.useRef();

  const [isValid, setIsValid] = React.useState(
    initialValidValue !== undefined
      ? initialValidValue
      : () =>
          validateValuesFn({
            values,
            rules,
            setFieldMeta,
            currentValidationRunnerRef,
            validatedValuesRef,
            setIsValid: arg => setIsValid(arg),
          })
  );

  const innerValidate = React.useCallback(
    valuesToValidate => {
      const retVal = validateValuesFn({
        values: valuesToValidate,
        rules,
        setFieldMeta,
        currentValidationRunnerRef,
        setIsValid,
        validatedValuesRef,
      });
      setIsValid(retVal);
      return retVal;
    },
    [rules, setFieldMeta]
  );

  const handleSubmit = React.useCallback(
    e => {
      if (e && e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (submitting) return false;
      setSubmitCount(c => c + 1);
      const doSubmit = (v, b) => {
        const res = onSubmit(v, b);
        if (res && "then" in res) {
          setSubmitting(true);
          res.then(
            val => {
              setSubmitting(false);
              return val;
            },
            val => {
              setSubmitting(false);
              return Promise.reject(val);
            }
          );
        }
      };
      let readyToSubmit = null;
      if (validatedValuesRef.current !== values) {
        readyToSubmit = innerValidate(values);
      } else {
        readyToSubmit = isValid;
      }
      if (readyToSubmit === true) {
        doSubmit(values, bagRef.current);
      } else if (readyToSubmit && "then" in readyToSubmit) {
        setSubmitting(true);
        readyToSubmit.then(isActuallyValid => {
          setSubmitting(false);
          if (isActuallyValid) {
            doSubmit(values, bagRef.current);
          }
        });
      }
    },
    [innerValidate, isValid, onSubmit, submitting, values]
  );

  const bag = {
    validateOn,
    values,
    setValues,
    isValid: typeof isValid === "object" ? "pending" : isValid,
    isValidOrPromise: isValid,
    submitCount,
    fieldMetas,
    setFieldMeta,
    submitting,
    setSubmitting,
    validate: () => innerValidate(values),
    validateValues: innerValidate,
    handleSubmit,
  };
  bagRef.current = bag;

  return bag;
};

export const NeoFormProvider = ({bag, children}) => (
  <FormCtx.Provider value={bag}>{children}</FormCtx.Provider>
);

export const useNeoBag = () => React.useContext(FormCtx);

export const useNeoField = name => {
  const bag = useNeoBag();
  const {fieldMetas, values, setValues, setFieldMeta, validateOn, validateValues, validate} = bag;
  const fieldProps = {
    value: values[name],
    onChange: value => {
      setValues(prev => {
        const next = prev[name] === value ? prev : {...prev, [name]: value};
        if (validateOn === "change") validateValues(next);
        return next;
      });
      setFieldMeta(name, prev => (prev.changed ? prev : {...prev, changed: true}));
    },
    onBlur: () => {
      if (validateOn === "blur") validate();
      setFieldMeta(name, prev => (prev.focussed ? {...prev, focussed: false} : prev));
    },
    onFocus: () =>
      setFieldMeta(name, prev => {
        let next = prev;
        if (!next.touched) next = {...next, touched: true};
        if (!next.focussed) next = {...next, focussed: true};
        return next;
      }),
  };

  return [fieldProps, fieldMetas[name] || initialFieldMeta];
};
