import React from "react";
import {InputWithLabel} from "../../components/Input";
import {NeoFormProvider, useNeoBagCreator, useNeoBag, useNeoField} from "./neo-form";
import {PlainButton} from "../../components/Button";
import Col from "../../components/ui/Col";
import {Text} from "../../components/ui/Text";
import {RawSpinner} from "../../components/ui/Spinner";
import Row from "../../components/ui/Row";

const ShowError = ({children}) => (
  <Col pb={2}>
    <Text preset="bold" size={1} color="accent200">
      {children}
    </Text>
  </Col>
);

const XForm = React.forwardRef(
  (
    {
      initialValues,
      rules,
      initialValidValue,
      values,
      onChange,
      style,
      className,
      onSubmit,
      buttonLabel,
      buttonComp: ButtonComp,
      buttonProps,
      serverErrorComp: ServerErrorComp = ShowError,
      children,
    },
    ref
  ) => {
    const [serverError, setServerError] = React.useState(null);
    const [isDone, setIsDone] = React.useState(false);
    const timeoutIdRef = React.useRef(null);
    React.useEffect(() => {
      return () => {
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      };
    }, []);
    const onSubmitRef = React.useRef(onSubmit);
    React.useEffect(() => {
      onSubmitRef.current = onSubmit;
    }, [onSubmit]);
    const handleSubmit = React.useCallback((submittedValues, bag) => {
      const retVal = onSubmitRef.current(submittedValues, bag);
      setIsDone(false);
      setServerError(null);
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      if (retVal && retVal.then) {
        return retVal.then(
          ok => {
            if (ok !== false) {
              setIsDone(true);
              timeoutIdRef.current = setTimeout(() => {
                setIsDone(false);
              }, 2000);
            }
          },
          e => {
            if (typeof e === "object" && !(e instanceof Error)) {
              if (Object.keys(e).every(k => bag.values[k])) {
                Object.entries(e).forEach(([key, val]) => {
                  bag.setFieldMeta(key, m => ({...m, errors: [...m.errors, val]}));
                });
                return;
              }
            }
            setServerError(e ? e.message || e.toString() : "Oh no! Something bad happened!");
          }
        );
      }
    }, []);

    const bag = useNeoBagCreator({
      values: values,
      onChange: onChange,
      initialValues: initialValues,
      onSubmit: handleSubmit,
      rules: rules,
      initialValidValue: initialValidValue,
    });

    return (
      <NeoFormProvider bag={bag}>
        <form className={className} style={style} onSubmit={bag.handleSubmit} ref={ref}>
          {serverError && <ServerErrorComp>{serverError}</ServerErrorComp>}
          {children}
          {buttonLabel && (
            <FormButton formBag={bag} isDone={isDone}>
              {buttonLabel}
            </FormButton>
          )}
          {ButtonComp && <ButtonComp formBag={bag} isDone={isDone} {...buttonProps} />}
        </form>
      </NeoFormProvider>
    );
  }
);

const SubmitButton = ({formBag, isDone, as: ButtonComp, disabled, ...rest}) => (
  <Col relative>
    <ButtonComp type="submit" disabled={disabled || formBag.submitting} {...rest} />
    {formBag.submitting && (
      <Row inset="y" absolute align="center" pl={2} style={{left: "100%"}}>
        <RawSpinner />
      </Row>
    )}
  </Col>
);

const FormButton = props => (
  <Col align="center">
    <SubmitButton as={PlainButton} size="big" {...props} />
  </Col>
);

const useFocusOnError = ({fieldRef, name, bag}) => {
  const prevSubmitCountRef = React.useRef(bag.submitCount);
  const firstErrorKey = Object.keys(bag.fieldMetas).find(
    fieldName => bag.fieldMetas[fieldName].errors.length > 0
  );
  React.useEffect(() => {
    if (prevSubmitCountRef.current !== bag.submitCount && bag.isValid !== true) {
      if (fieldRef.current && firstErrorKey === name) {
        fieldRef.current.focus();
      }
    }
    prevSubmitCountRef.current = bag.submitCount;
  }, [bag.submitCount, bag.isValid, firstErrorKey, fieldRef, name]);
};

const Field = ({as: Comp = InputWithLabel, name, ...props}) => {
  const fieldRef = React.useRef();
  const [field, meta] = useNeoField(name);
  const bag = useNeoBag();
  useFocusOnError({fieldRef, name, bag});

  const errors = (((!meta.focussed && meta.changed) || bag.submitCount > 0) && meta.errors) || [];
  const allProps = {
    ref: fieldRef,
    name,
    errors: errors.slice(0, 1),
    hasPendingValidation: meta.pendingValidation,
    ...props,
    ...field,
  };
  return <Comp {...allProps} />;
};

XForm.Field = Field;

const rules = {
  isRequired: [v => v !== null && v !== undefined && v !== "", "Required"],
  isEmail: [v => /\S+@\S+\.\S+/.test(v), "Not a valid email"],
  isDigits: [v => /^\d*$/.test(v), "Digits only"],
  minLength: num => [v => v.length >= num, `Needs to be at least ${num} characters long`],
  maxLength: num => [v => v.length <= num, `May have at most ${num} characters`],
};

export {XForm, rules, SubmitButton};
