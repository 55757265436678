import React from "react";

const debouncePromise = (fn, ms) => {
  let id = null;
  const debounced = (...args) => {
    if (id) clearTimeout(id);
    return new Promise(resolve => {
      id = setTimeout(() => resolve(fn(...args)), ms);
    });
  };

  debounced.cancel = () => {
    if (id) clearTimeout(id);
  };

  return debounced;
};

// either returns the cached value or a promise containing the value

const useCacheAndDebounce = fn => {
  const fnRef = React.useRef();
  const cachedRef = React.useRef({});
  if (!fnRef.current)
    fnRef.current = debouncePromise(val => {
      if (!cachedRef.current[val]) {
        const result = fn(val);
        cachedRef.current[val] = result;
        return result.then(r => {
          cachedRef.current[val] = r;
          return r;
        });
      }
      return cachedRef.current[val];
    }, 500);
  React.useEffect(() => {
    return () => fnRef.current.cancel();
  }, []);
  return React.useCallback(val => {
    return val in cachedRef.current ? cachedRef.current[val] : fnRef.current(val);
  }, []);
};

export default useCacheAndDebounce;
