import React from "react";
import css from "@emotion/css";

export const rawButtonStyles = css({
  transitionProperty: "background-color, color, box-shadow, border-color, opacity",
  fontFamily: "inherit",
  fontSize: "inherit",
  textAlign: "center",
  width: "auto",
  display: "block",
  textDecoration: "none",
  border: "none",
  backgroundColor: "transparent",
  userSelect: "none",
  cursor: "pointer",
  padding: 0,
});

export const ButtonBehaviour = React.forwardRef(
  ({comp: Comp, onClick, disabled, tooltip, tooltipProps, ...rest}, rawRef) => {
    const [pending, setPending] = React.useState(false);
    const unmountedRef = React.useRef();
    const compRef = React.useRef();
    const ref = rawRef || compRef;
    React.useEffect(
      () => () => {
        unmountedRef.current = true;
      },
      []
    );

    const handleClick = (...args) => {
      const retVal = onClick(...args);
      if (retVal && typeof retVal.then === "function") {
        setPending(true);
        retVal.then(
          () => !unmountedRef.current && setPending(false),
          error => {
            console.error("button click resulted in ", error);
            // messenger.send((error.message || error).toString(), 10000);
            if (!unmountedRef.current) setPending(false);
            return Promise.reject(error);
          }
        );
      }
    };

    return (
      <Comp
        ref={ref}
        disabled={pending ? true : disabled}
        onClick={onClick ? handleClick : undefined}
        {...rest}
      />
    );
  }
);
